<template>
  <v-app>
    <div class="order" id="order">
      <!-- {{this.getServiceOrderType}} -->
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            dark
            class="buttons--add order__btn no-print"
            style="margin-right: 50px"
            fab
            small
            @click="print()"
          >
            <v-icon size="18" dark>mdi-printer</v-icon>
          </v-btn>
        </template>
        <span>Drukuj zlecenie</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            dark
            class="buttons--add order__btn no-print"
            style="margin-right: 100px"
            fab
            small
            @click="generatePDF()"
          >
            <v-icon size="18" dark>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>Pobierz</span>
      </v-tooltip>

      <div class="text-center">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  dark
                  @click="menu = true"
                  class="buttons--add order__btn no-print"
                  fab
                  small
                >
                  <v-icon size="18" dark>mdi-email-send</v-icon>
                </v-btn>
              </template>
              <span>Wyślij do klienta</span>
            </v-tooltip>
          </template>

          <v-card class="pa-4" height="110">
            <v-text-field
              dense
              label="Adres email do wysyłki"
              v-model="clientEmail"
              :error-messages="errorMessages"
            >
              <template slot="prepend">
                <v-icon color="#19aa8d" class="mt-1" size="20"
                  >mdi-email</v-icon
                >
              </template>
            </v-text-field>
            <v-btn
              @click="sendToEmail()"
              dark
              class="buttons buttons--add no-print"
              style="position: absolute; right: 30px"
              >Wyślij</v-btn
            >
          </v-card>
        </v-menu>
      </div>

      <div id="firsPage" class="pdf-page1">
        <div v-if="company === 'Biuromax-Balcer'" class="order__header">
          <img
            style="margin-left: -50px"
            src="@/assets/img/bmx/logo2.svg"
            alt="logo"
          />
          <ul style="min-width: 300px !important">
            <li>PW "BIUROMAX-BALCER" Sp. z o.o.</li>
            <li>ul. Jagielońska 103, 85-027 Bydgoszcz</li>
            <li>tel. 52 / 3461237, 663 789 450</li>
            <li>serwis@biuromax-balcer.pl</li>
            <li>www.biuromax-balcer.pl</li>
          </ul>
        </div>
        <div v-else class="order__header">
          <img
            style="height: 40px !important; min-width: 0 !important"
            src="@/assets/img/bikop/bikop.jpg"
            alt="logo"
          />

          <ul style="min-width: 300px !important">
            <li>P.H.U."BIKOP" EUGENIUSZ BIGOS</li>
            <li>Ul. Derdowskiego 7, 80-319 Gdańsk</li>
            <li>tel. 058 340 40 05, fax 058 340 40 01</li>
            <li>a.mielewska@bikop.pl</li>
            <li>www.bikop.pl</li>
          </ul>
        </div>

        <div class="order__center pb-5 mb-5">
          <div class="order__center__left">
            <div class="order__center__title">
              Klient
              {{
                getServiceDetails.device && getServiceDetails.device.client
                  ? getServiceDetails.device.client.contactId
                  : ''
              }}
            </div>
            <div class="order__center__left__box">
              <ul>
                <li>
                  <span>{{
                    getServiceDetails.device.client
                      ? getServiceDetails.device.client.name
                      : ''
                  }}</span>
                </li>
                <li>
                  <span>
                    {{
                      getServiceDetails.device.client
                        ? getServiceDetails.device.client.street
                        : ''
                    }}
                    {{
                      getServiceDetails.device.client
                        ? getServiceDetails.device.client.houseNumber
                        : ''
                    }}
                    <span
                      v-if="
                        getServiceDetails.device.client &&
                          getServiceDetails.device.client.apartmentNumber
                      "
                      >/{{
                        getServiceDetails.device.client.apartmentNumber
                          ? getServiceDetails.device.client.apartmentNumber
                          : ''
                      }}</span
                    >
                  </span>
                </li>
                <li>
                  <span>
                    <li>
                      {{
                        getServiceDetails.device.client
                          ? getServiceDetails.device.client.postCode
                          : ''
                      }}
                      {{
                        getServiceDetails.device.client
                          ? getServiceDetails.device.client.city
                          : ''
                      }}
                    </li>
                  </span>
                </li>
                <li>
                  <span>{{
                    getServiceDetails.device.client
                      ? getServiceDetails.device.client.phoneNumber
                      : ''
                  }}</span>
                </li>
              </ul>
            </div>
            <div class="order__center__title">Osoba Zlecająca</div>
            <div class="order__center__left__box">
              <ul>
                <li>
                  <span
                    v-if="
                      getServiceDetails.service.descriptionIntervention
                        .representative
                    "
                  >
                    {{
                      getServiceDetails.service.descriptionIntervention
                        .representative.name
                    }}
                    {{
                      getServiceDetails.service.descriptionIntervention
                        .representative.lastname
                    }}
                    <br />
                    tel.
                    {{
                      getServiceDetails.service.descriptionIntervention
                        .representative.phoneNumber
                    }}
                    <br />
                    email.{{
                      getServiceDetails.service.descriptionIntervention
                        .representative.email
                    }}
                  </span>
                </li>
              </ul>
            </div>
            <div class="order__center__title">Data usługi</div>
            <div class="order__center__left__box">
              <ul>
                <!-- <li>
                  <span
                    v-if="getServiceDetails.implementation.service.dates"
                  >{{correctData(getServiceDetails.implementation.service.dates.start) }}</span>
                </li>-->
              </ul>
            </div>
            <div class="order__center__title">Technik Serwisu</div>
            <div class="order__center__left__box">
              <ul>
                <li>
                  <span
                    v-if="
                      getServiceDetails.service.descriptionIntervention
                        .technicPerforming
                    "
                    >{{
                      getServiceDetails.service.descriptionIntervention
                        .technicPerforming.name
                    }}
                    {{
                      getServiceDetails.service.descriptionIntervention
                        .technicPerforming.lastname
                    }}</span
                  >
                </li>
              </ul>
            </div>
            <div class="order__center__title d-flex">
              Urządzenie
              <span
                style="margin-left: auto; margin-right: 25px"
                v-if="getServiceDetails.service.realisation.warranty"
                >G</span
              >
            </div>
            <div class="order__center__left__box">
              <ul>
                <li>
                  Model:
                  <span>{{ getServiceDetails.device.modelDevices.model }}</span>
                </li>
                <li>
                  Producent:
                  <span>{{
                    getServiceDetails.device.modelDevices.producer
                  }}</span>
                </li>
                <li>
                  S/N:
                  <span>{{ getServiceDetails.device.SN }}</span>
                </li>

                <li>
                  Podstawa serwisu:
                  <span>{{ getServiceDetails.device.serviceBase }}</span>
                </li>
                <li v-if="company === 'Biuromax-Balcer'">
                  Gwarancja do:
                  <span>{{
                    getServiceDetails.device.warrantyTo
                      ? correctData(getServiceDetails.device.warrantyTo)
                      : ''
                  }}</span>
                </li>
                <li>
                  Lokalizacja:
                  <span
                    >{{ getServiceDetails.device.address.address }}
                    {{ getServiceDetails.device.address.postCode }}
                    {{ getServiceDetails.device.address.city }}</span
                  >
                  <br />
                  <span v-if="getServiceDetails.device.floor"
                    >Piętro: {{ getServiceDetails.device.floor }}</span
                  >
                </li>
                <!-- <li>
                                Czas realizacji:
                                <span>24h</span>
                </li>-->
              </ul>
            </div>
            <div
              v-if="this.getServiceOrderType.type != 'logistic'"
              class="order__center__title"
            >
              Liczniki urządzenia
            </div>
            <div
              v-if="this.getServiceOrderType.type != 'logistic'"
              class="order__center__left__box"
            >
              <ul>
                <li>
                  Mono P:
                  {{
                    getServiceDetails.implementation
                      ? getServiceDetails.implementation.meter.mono.start
                      : ''
                  }}
                </li>
                <li>
                  Mono K:
                  {{
                    getServiceDetails.implementation
                      ? getServiceDetails.implementation.meter.mono.end
                      : ''
                  }}
                </li>
                <li>
                  Kolor P:
                  {{
                    getServiceDetails.implementation
                      ? getServiceDetails.implementation.meter.color.start
                      : ''
                  }}
                </li>
                <li>
                  Kolor K:
                  {{
                    getServiceDetails.implementation
                      ? getServiceDetails.implementation.meter.color.end
                      : ''
                  }}
                </li>
              </ul>
            </div>
            <!-- <div class="order__center__title">Samochód</div>
            <div class="order__center__left__box">
              <ul>
                <li>
                  Rejestracja:
                  <span>{{getServiceDetails.implementation ? getServiceDetails.implementation.plate : ''}}</span>
                </li>
                <li>Licznik P: {{getServiceDetails.implementation ? getServiceDetails.implementation.meter.car.start : ''}}</li>
                <li>Licznik K: {{getServiceDetails.implementation ? getServiceDetails.implementation.meter.car.end : ''}}</li>
              </ul>
            </div>-->
            <div v-if="getServiceDetails.implementation">
              <div
                v-if="
                  getServiceDetails.implementation.comment.irrational &&
                    this.getServiceOrderType.type != 'logistic'
                "
                class="order__center__title"
              >
                Interwencja nieuzasadniona
              </div>
            </div>
            <!-- <div class="order__center__left__box" style="min-width 300px !important">
              <ul>
                <li class="d-flex" v-if="getServiceDetails.implementation.comment.irrational">
                  <v-icon size="15" class="mr-2">mdi-check-box-outline</v-icon>
                  <span>Nieuzasadniona</span>
                </li>
              </ul>
            </div>-->
          </div>
          <div class="order__center__right">
            <div class="order__center__title d-flex align-center">
              <h3 v-if="company === 'Biuromax-Balcer'" style="width: 100%">
                Protokół interwencji serwisu: {{ getServiceDetails.index }}
              </h3>
              <h3 v-else style="width: 100%">
                Protokół wykonania usługi: {{ getServiceDetails.index }}
              </h3>
              <span
                style="margin-left: 15px"
                v-if="getServiceDetails.service.realisation.continuation"
                >KONTYNUACJA</span
              >
            </div>
            <div class="order__center__title">
              Zakres usług:
              <span>{{
                getServiceDetails.device.modelDevices.price.priceName
              }}</span>
            </div>
            <table border="1" class="order__center__right__service">
              <tbody>
                <tr>
                  <td>Przegląd okresowy</td>
                  <td>Naprawa</td>
                  <td>Wdrożenie/Instalacja</td>
                  <td>Diagnostyka</td>
                  <td>Usługa IT</td>
                </tr>
                <tr>
                  <td>
                    {{
                      getServiceDetails.implementation
                        ? getServiceDetails.implementation.job.survey.time
                        : ''
                    }}
                    h
                  </td>
                  <td>
                    {{
                      getServiceDetails.implementation
                        ? getServiceDetails.implementation.job.repair.time
                        : ''
                    }}
                    h
                  </td>
                  <td>
                    {{
                      getServiceDetails.implementation
                        ? getServiceDetails.implementation.job.installation.time
                        : ''
                    }}
                    h
                  </td>
                  <td>
                    {{
                      getServiceDetails.implementation
                        ? getServiceDetails.implementation.job.diagnostics.time
                        : ''
                    }}
                    h
                  </td>
                  <td>
                    {{
                      getServiceDetails.implementation
                        ? getServiceDetails.implementation.job.itService.time
                        : ''
                    }}
                    h
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="company === 'Biuromax-Balcer'"
              class="order__center__title"
            >
              Opis interwencji wg. Klienta
            </div>
            <div v-else class="order__center__title">
              Opis zgłoszenia wg. Klienta
            </div>
            <div
              class="order__center__right__client"
              style="padding: 3px; font-size: 11px"
            >
              <span>{{
                getServiceDetails.service.descriptionIntervention
                  .descriptionClient
              }}</span>
            </div>
            <div
              v-if="this.getServiceOrderType.type != 'client'"
              class="order__center__title"
            >
              Opis logistyka
            </div>
            <div
              v-if="this.getServiceOrderType.type != 'client'"
              class="order__center__right__logistic"
              style="padding: 3px; font-size: 11px"
            >
              <span>{{
                getServiceDetails.service.descriptionIntervention
                  .descriptionLogistic
              }}</span>
            </div>
            <div
              v-if="this.getServiceOrderType.type != 'client'"
              class="order__center__title"
            >
              Informacje z poprzedniej interwencji
            </div>

            <table border="1" class="order__center__right__information">
              <tbody v-if="this.getServiceOrderType.type != 'client'">
                <tr>
                  <td>Licznik mono</td>
                  <td>
                    {{
                      this.getLastServiceDetails &&
                      this.getLastServiceDetails.implementation
                        ? this.getLastServiceDetails.implementation.meter.mono
                            .end
                        : ''
                    }}
                  </td>
                  <td>Technik</td>
                  <td>
                    {{
                      `${
                        this.getLastServiceDetails &&
                        this.getLastServiceDetails.implementation
                          ? this.getLastServiceDetails.implementation.technic
                              .name +
                            ' ' +
                            this.getLastServiceDetails.implementation.technic
                              .lastname
                          : ''
                      }`
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Licznik kolor</td>
                  <td>
                    {{
                      this.getLastServiceDetails &&
                      this.getLastServiceDetails.implementation
                        ? this.getLastServiceDetails.implementation.meter.color
                            .end
                        : ''
                    }}
                  </td>
                  <td>Data</td>
                  <td>
                    {{
                      this.getLastServiceDetails &&
                      this.getLastServiceDetails.implementation &&
                      this.getLastServiceDetails.implementation.service.dates
                        .end
                        ? correctData(
                            this.getLastServiceDetails.implementation.service
                              .dates.end
                          )
                        : ''
                    }}
                  </td>
                </tr>
                <tr style="height: 130px">
                  <td>Zalecenia z ostatniej interwencji</td>
                  <td>
                    {{
                      this.getLastServiceDetails &&
                      this.getLastServiceDetails.implementation
                        ? this.getLastServiceDetails.implementation.description
                            .nextIntervention
                        : ''
                    }}
                  </td>
                  <td>Zakres ostatniej interwencji</td>
                  <td>
                    {{
                      this.getLastServiceDetails &&
                      this.getLastServiceDetails.implementation
                        ? this.getLastServiceDetails.implementation.description
                            .doneJob
                        : ''
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="company === 'Biuromax-Balcer'"
              class="order__center__title"
            >
              Opis interwencji
            </div>
            <div v-else class="order__center__title">Opis pracy technika</div>
            <div
              class="order__center__right__intervention"
              style="padding: 3px; font-size: 11px"
            >
              {{
                this.getServiceDetails.implementation
                  ? this.getServiceDetails.implementation.description.doneJob
                  : ''
              }}
            </div>

            <table border="1" class="order__center__right__parts">
              <tbody
                v-if="
                  getServiceDetails.device.serviceBase == 'Podwykonawstwo' ||
                    getServiceDetails.device.serviceBase ==
                      'Inne (opis w uwagach)' ||
                    getServiceDetails.device.serviceBase == 'Serwis tradycyjny'
                "
              >
                <tr>
                  <th>Lp.</th>
                  <th>Nazwa</th>
                  <th>Cena netto</th>
                  <th>% VAT</th>
                  <th>Wartość VAT</th>
                  <th>Wartość brutto</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Części / Materiały eksploatacyjne</td>
                  <td>
                    <money
                      :value="nettoParts"
                      v-bind="cost"
                      style="width: 80px"
                      disabled
                    ></money>
                  </td>
                  <td>23%</td>
                  <td>
                    <money
                      :value="vatParts"
                      v-bind="cost"
                      style="width: 80px"
                      disabled
                    ></money>
                  </td>
                  <td>
                    <money
                      :value="bruttoParts"
                      v-bind="cost"
                      style="width: 80px"
                      disabled
                    ></money>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Usługa serwisu / IT</td>
                  <td>
                    <money
                      :value="nettoService"
                      v-bind="cost"
                      style="width: 80px"
                      disabled
                    ></money>
                  </td>
                  <td>23%</td>
                  <td>
                    <money
                      :value="vatService"
                      v-bind="cost"
                      style="width: 80px"
                      disabled
                    ></money>
                  </td>
                  <td>
                    <money
                      :value="bruttoService"
                      v-bind="cost"
                      style="width: 80px"
                      disabled
                    ></money>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Transport</td>
                  <td>
                    <money
                      :value="nettoCar"
                      v-bind="cost"
                      style="width: 80px"
                      disabled
                    ></money>
                  </td>
                  <td>23%</td>
                  <td>
                    <money
                      :value="vatCar"
                      v-bind="cost"
                      style="width: 80px"
                      disabled
                    ></money>
                  </td>
                  <td>
                    <money
                      :value="bruttoCar"
                      v-bind="cost"
                      style="width: 80px"
                      disabled
                    ></money>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <th>Lp.</th>
                  <th>Nazwa</th>
                  <th>Cena netto</th>
                  <th>% VAT</th>
                  <th>Wartość VAT</th>
                  <th>Wartość brutto</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Części / Materiały eksploatacyjne</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Usługa serwisu / IT</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Transport</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="order__footer pt-5">
          <div>
            <div class="ma-0" style="min-width: 360px">
              <div class="d-flex" v-if="getServiceDetails.implementation">
                <p style="min-width: 360px">
                  Stwierdzam poprawność pracy urządzenia po wykonanej usłudze
                </p>
                <span
                  style="margin-left: 16px"
                  v-if="getServiceDetails.implementation.comment.machineWorks"
                  >TAK</span
                >
                <span style="margin-left: 16px" v-else>NIE</span>
              </div>
            </div>
            <div class="ma-0" style="min-width: 360px">
              <div
                class="d-flex"
                style="min-width: 360px"
                v-if="getServiceDetails.implementation"
              >
                <p>
                  Niniejszy protokół jest podstawą do wystawienia faktury VAT
                </p>
                <span
                  v-if="
                    getServiceDetails.implementation.requirements.invoice
                      .checkbox
                  "
                  >TAK</span
                >
                <span v-else>NIE</span>
              </div>
            </div>
            <!-- <p class="ma-0">
              Kopię niniejszego protokołu otrzymał Klient
              <span style="min-width: 100px">TAK / NIE</span>
            </p>-->
          </div>
          <div class="order__footer__signature">
            <p>
              Podpis Klienta
              <span>......................</span>
            </p>
            <span
              style="
                width: 100px;
                height: 30px;
                position: absolute;
                top: 20px;
                left: 55px;
              "
            >
              <img
                v-if="
                  getServiceDetails.implementation &&
                    getServiceDetails.implementation.signature.img
                "
                width="65px"
                :src="getServiceDetails.implementation.signature.img"
              />
            </span>
            <p>
              Podpis Technika
              <span>......................</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Snackbars />
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import Snackbars from '@/components/Global/Snackbars/Snackbars.vue';
import jsPDF from 'jspdf';
import moment from 'moment-timezone';
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';
import { Money } from 'v-money';
import 'jspdf-autotable';
export default {
  data: () => ({
    file: '',
    clientEmail: '',
    fav: true,
    company: `${process.env.VUE_APP_FIRM}`,
    // vat: '',
    // netto: '',
    // brutto: '',
    cost: {
      decimal: ',',
      thousands: ',',
      suffix: ' PLN',
      precision: 2,
      masked: false,
    },
    on: '',
    errorMessages: '',
    menu: false,
    message: false,
    hints: true,
  }),
  components: { Snackbars },
  computed: {
    ...mapGetters([
      'getServiceDetails',
      'getGlobalPaths',
      // "getLastService",
      'getserviceParts',
      'getServiceOrderType',
      'getLastServiceDetails',
    ]),
    vatService: {
      get() {
        let vat = 23;
        if (this.getServiceDetails.implementation) {
          return (
            (this.getServiceDetails.implementation.cost.normal * vat) / 100
          );
        }
      },
    },
    nettoService: {
      get() {
        if (this.getServiceDetails.implementation) {
          return this.getServiceDetails.implementation.cost.normal;
        }
      },
    },
    bruttoService: {
      get() {
        let vat = 23;
        if (this.getServiceDetails.implementation) {
          return (
            this.getServiceDetails.implementation.cost.normal + this.vatService
          );
        }
      },
    },
    vatParts: {
      get() {
        let vat = 23;
        if (this.getserviceParts) {
          return (this.getserviceParts.totalSum * vat) / 100;
        }
      },
    },
    nettoParts: {
      get() {
        if (this.getserviceParts) {
          return this.getserviceParts.totalSum;
        }
      },
    },
    bruttoParts: {
      get() {
        let vat = 23;
        if (this.getserviceParts) {
          return this.getserviceParts.totalSum + this.vatParts;
        }
      },
    },
    vatCar: {
      get() {
        let vat = 23;
        if (this.getServiceDetails.implementation) {
          return (
            (this.getServiceDetails.implementation.meter.car.cost * vat) / 100
          );
        }
      },
    },
    nettoCar: {
      get() {
        if (this.getServiceDetails.implementation) {
          return this.getServiceDetails.implementation.meter.car.cost;
        }
      },
    },
    bruttoCar: {
      get() {
        let vat = 23;
        if (this.getServiceDetails.implementation) {
          return (
            this.getServiceDetails.implementation.meter.car.cost + this.vatCar
          );
        }
      },
    },
  },
  methods: {
    ...mapMutations(['setSnackbars']),
    correctData(date) {
      return this.$moment(date).format('YYYY-MM-DD');
    },
    print() {
      window.print();
      this.$router.push('/service');
    },
    generatePDF() {
      let node = document.getElementById('firsPage');
      const scale = 1;
      let obj = {
        height: 1100,
        // style: {
        //   transform: `scale(${scale}) translate(${1900 / 2 / scale}px, ${1100 /
        //     2 /
        //     scale}px)`
        // },
        width: 770,
      };
      // let obj = {
      //   height: 1200 * scale,
      //   style: {
      //     transform: `scale(${scale}) translate(${1900 / 2 / scale}px, ${1100 /
      //       2 /
      //       scale}px)`
      //   },
      //   width: 1100 * scale
      // };
      setTimeout(function() {
        document.getElementById(
          'order'
        ).innerHTML = `<div class="printContent"><div class="printQRCode" style="font-size: 18px; text-align: center; line-height: 2; margin-left: -15px; margin-top: -40px; width: 240px">${'Trwa generowanie PDF...'}</div><div class="printQRCode" style="font-size: 11px"></div></div><div id="preloader"><div id="loader">
                </div></div>`;
      }, 100);

      domtoimage.toPng(node, obj).then(function(dataUrl) {
        let img = new Image();
        img.src = dataUrl;
        let doc = new jsPDF();
        doc.internal.scaleFactor = 3;
        doc.addImage(img, 'png', 10, 5);
        doc.save('Zlecenie.pdf');
        location.reload();
      });
    },

    sendToEmail() {
      let node = document.getElementById('firsPage');

      const scale = 1;
      let obj = {
        height: 1100,
        // style: {
        //   transform: `scale(${scale}) translate(${1900 / 2 / scale}px, ${1100 /
        //     2 /
        //     scale}px)`
        // },
        width: 770,
      };

      let client = this.clientEmail;
      let index = this.getServiceDetails.index;

      if (client === '') {
        this.errorMessages = 'Pole wymagane';
      } else {
        setTimeout(function() {
          document.getElementById(
            'order'
          ).innerHTML = `<div class="printContent"><div class="printQRCode" style="font-size: 18px; text-align: center; line-height: 2; width: 250px">${'Trwa wysyłanie maila proszę czekać...'}</div><div class="printQRCode" style="font-size: 11px"></div></div><div id="preloader"><div id="loader">
                                        </div></div>`;
        }, 100);
        document.body.style.overflow = 'hidden !important';
        this.errorMessages = '';
        let that = this;
        this.menu = false;

        const chars =
          '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        let code = '';
        for (let i = 0; i < 6; i++) {
          let index = Math.floor(Math.random() * chars.length);
          code += chars[index];
        }
        domtoimage.toPng(node, obj).then(function(dataUrl) {
          let img = new Image();
          img.src = dataUrl;
          let doc = new jsPDF();
          doc.internal.scaleFactor = 3;
          doc.addImage(img, 'png', 10, 5);
          var fd = new FormData();
          fd.append('case', 'pdf');
          fd.append('mail', `${client}`);
          fd.append('index', `${index}`);
          fd.append('code', `${code}`);
          fd.append('upl', doc.output('blob'));

          Vue.axios({
            method: 'POST',
            url: `/sendOrder`,
            data: fd,
          })
            .then(() => {
              that.setSnackbars({
                type: 'success',
                text: 'Załącznik został wysłany',
              });
              setTimeout(() => {
                location.reload();
              }, 2000);
            })
            .catch(() => {
              that.setSnackbars({
                type: 'error',
                text: 'Ups! Błąd wysyłania',
              });

              setTimeout(() => {
                location.reload();
              }, 2000);
            });
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
h1
  font-size: 10rem
  color: red
</style>
